import Header from "../../Components/Header/header";
import Footer from "../../Components/Footer/footer";

export default function About() {
    return (
        <body>
            <Header/>
            <main>
                <h2>About</h2>
                <p>this site is in a build state</p>
                <a href="https://business-card.lolerswager.com/">my business card :D</a>
            </main>
            <Footer /> 
        </body>
    );
}