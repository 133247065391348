import './Footer.css';

export default function Footer() {
  return (
    <footer>
        <div className="underbar">
          <p>2016-2022 LolerSwager.com</p>
        </div>
    </footer>
  );
}